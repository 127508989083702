import {Component, OnInit} from '@angular/core';
import {H1TemplateComponent} from "../../components/header/h1-template/h1-template.component";
import {OfferAdvantagesComponent} from "../../components/content/offer-advantages/offer-advantages.component";
import {ManagementFeesComponent} from "../../components/content/management-fees/management-fees.component";
import {FEPSComponent} from "../../components/content/feps/feps.component";
import {Meta, Title} from "@angular/platform-browser";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-notre-offre',
  standalone: true,
  imports: [
    H1TemplateComponent,
    OfferAdvantagesComponent,
    ManagementFeesComponent,
    FEPSComponent
  ],
  templateUrl: './notre-offre.component.html',
  styleUrl: './notre-offre.component.css'
})
export class NotreOffreComponent implements OnInit {

  constructor(private titleService: Title, private metaService: Meta, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.titleService.setTitle('Notre Offre');

    this.metaService.updateTag({
      name: 'description',
      content: 'Découvrez les nombreux avantages de notre offre de portage salarial, conçue pour offrir une flexibilité totale tout en garantissant la sécurité et la protection des indépendants.'
    });

    this.metaService.updateTag({property: 'og:title', content: 'Notre Offre'});
    this.metaService.updateTag({
      property: 'og:description',
      content: 'Découvrez les nombreux avantages de notre offre de portage salarial, conçue pour offrir une flexibilité totale tout en garantissant la sécurité et la protection des indépendants.'
    });
    this.metaService.updateTag({
      property: 'og:image',
      content: 'https://www.portagile.fr/assets/brand/logoAndTitleReverseBlue-400.webp'
    });
  }

}
