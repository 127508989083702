<div class="form-container">
  <form [formGroup]="cooptationForm" (ngSubmit)="onSubmit()" class="form">
    <p class="title">Cooptation</p>
    <p class="message">Veuillez entrer vos informations ainsi que celles de la personne que vous souhaitez coopter.</p>

    <!-- Coopteur - Nom -->
    <label>
      <input id="coopteur_nom" type="text" formControlName="coopteur_nom" placeholder="" class="input" required>
      <span>Nom</span>
      <div *ngIf="cooptationForm.get('coopteur_nom')?.invalid && cooptationForm.get('coopteur_nom')?.touched"
           class="error">
        Veuillez entrer le nom du coopteur.
      </div>
    </label>

    <!-- Coopteur - Prénom -->
    <label>
      <input id="coopteur_prenom" type="text" formControlName="coopteur_prenom" placeholder="" class="input" required>
      <span>Prénom</span>
      <div *ngIf="cooptationForm.get('coopteur_prenom')?.invalid && cooptationForm.get('coopteur_prenom')?.touched"
           class="error">
        Veuillez entrer le prénom du coopteur.
      </div>
    </label>

    <!-- Coopteur - Mail -->
    <label>
      <input id="coopteur_mail" type="email" formControlName="coopteur_mail" placeholder="" class="input" required>
      <span>Email</span>
      <div *ngIf="cooptationForm.get('coopteur_mail')?.invalid && cooptationForm.get('coopteur_mail')?.touched"
           class="error">
        Veuillez entrer une adresse email valide pour le coopteur.
      </div>
    </label>

    <!-- Coopteur - Téléphone -->
    <label>
      <input id="coopteur_telephone" type="text" formControlName="coopteur_telephone" placeholder="" class="input"
             required>
      <span>Téléphone</span>
      <div
        *ngIf="cooptationForm.get('coopteur_telephone')?.invalid && cooptationForm.get('coopteur_telephone')?.touched"
        class="error">
        Veuillez entrer un numéro de téléphone valide pour le coopteur (10 chiffres).
      </div>
    </label>

    <!-- Cooptant - Nom -->
    <label>
      <input id="cooptant_nom" type="text" formControlName="cooptant_nom" placeholder="" class="input" required>
      <span>Nom du parrainé</span>
      <div *ngIf="cooptationForm.get('cooptant_nom')?.invalid && cooptationForm.get('cooptant_nom')?.touched"
           class="error">
        Veuillez entrer le nom du cooptant.
      </div>
    </label>

    <!-- Cooptant - Prénom -->
    <label>
      <input id="cooptant_prenom" type="text" formControlName="cooptant_prenom" placeholder="" class="input" required>
      <span>Prénom du parrainé</span>
      <div *ngIf="cooptationForm.get('cooptant_prenom')?.invalid && cooptationForm.get('cooptant_prenom')?.touched"
           class="error">
        Veuillez entrer le prénom du cooptant.
      </div>
    </label>

    <!-- Cooptant - Mail -->
    <label>
      <input id="cooptant_mail" type="email" formControlName="cooptant_mail" placeholder="" class="input" required>
      <span>Email du parrainé</span>
      <div *ngIf="cooptationForm.get('cooptant_mail')?.invalid && cooptationForm.get('cooptant_mail')?.touched"
           class="error">
        Veuillez entrer une adresse email valide pour le cooptant.
      </div>
    </label>

    <!-- Cooptant - Téléphone -->
    <label>
      <input id="cooptant_telephone" type="text" formControlName="cooptant_telephone" placeholder="" class="input"
             required>
      <span>Téléphone du parrainé</span>
      <div
        *ngIf="cooptationForm.get('cooptant_telephone')?.invalid && cooptationForm.get('cooptant_telephone')?.touched"
        class="error">
        Veuillez entrer un numéro de téléphone valide pour le cooptant (10 chiffres).
      </div>
    </label>

    <!-- Commentaire -->
    <label>
      <textarea id="commentaire" formControlName="commentaire" placeholder="" class="input" required></textarea>
      <span>Commentaire</span>
      <div *ngIf="cooptationForm.get('commentaire')?.invalid && cooptationForm.get('commentaire')?.touched"
           class="error">
        Veuillez entrer un commentaire.
      </div>
    </label>

    <label id="consentLabel">
      <input id="consent" type="checkbox" formControlName="consent" class="input" required>
      <span>J'accepte que mes informations soient utilisées conformément à la <a [routerLink]="[PORTAGILE_ROUTES_CGU]">politique de confidentialité</a>.</span>
    </label>

    <label id="cookiesConsentLabel">
      <input id="cookiesConsent" type="checkbox" formControlName="cookiesConsent" class="input" required>
      <span>J'accepte l'utilisation de cookies selon la <a [routerLink]="[PORTAGILE_ROUTES_COOKIES]">politique de gestion des cookies</a>.</span>
    </label>

    <button id="submitForm" type="submit" [disabled]="cooptationForm.invalid"
            [ngClass]="cooptationForm.valid ? 'validButton' : 'invalidButton'" class="submit">
      Envoyer

      <div *ngIf="cooptationForm.invalid" class="tooltip-text">
        <div *ngIf="cooptationForm.get('coopteur_nom')?.invalid ||
                 cooptationForm.get('coopteur_prenom')?.invalid ||
                 cooptationForm.get('coopteur_mail')?.invalid ||
                 cooptationForm.get('coopteur_telephone')?.invalid ||
                 cooptationForm.get('cooptant_nom')?.invalid ||
                 cooptationForm.get('cooptant_prenom')?.invalid ||
                 cooptationForm.get('cooptant_mail')?.invalid ||
                 cooptationForm.get('cooptant_telephone')?.invalid ||
                 cooptationForm.get('commentaire')?.invalid">
          Merci de remplir les champs suivants :
        </div>

        <div *ngIf="cooptationForm.get('coopteur_nom')?.invalid" class="error">
          - Nom du coopteur
        </div>

        <div *ngIf="cooptationForm.get('coopteur_prenom')?.invalid" class="error">
          - Prénom du coopteur
        </div>

        <div *ngIf="cooptationForm.get('coopteur_mail')?.invalid" class="error">
          - Email du coopteur
        </div>

        <div *ngIf="cooptationForm.get('coopteur_telephone')?.invalid" class="error">
          - Téléphone du coopteur
        </div>

        <div *ngIf="cooptationForm.get('cooptant_nom')?.invalid" class="error">
          - Nom du parrainé
        </div>

        <div *ngIf="cooptationForm.get('cooptant_prenom')?.invalid" class="error">
          - Prénom du parrainé
        </div>

        <div *ngIf="cooptationForm.get('cooptant_mail')?.invalid" class="error">
          - Email du parrainé
        </div>

        <div *ngIf="cooptationForm.get('cooptant_telephone')?.invalid" class="error">
          - Téléphone du parrainé
        </div>

        <div *ngIf="cooptationForm.get('commentaire')?.invalid" class="error">
          - Commentaire
        </div>

        <div *ngIf="(cooptationForm.get('consent')?.invalid || cooptationForm.get('cookiesConsent')?.invalid)"
             class="error consent-error">
          Veuillez accepter la politique de confidentialité et la gestion des cookies pour continuer.
        </div>
      </div>
    </button>
  </form>
</div>
