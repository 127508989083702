import {Component, OnInit} from '@angular/core';
import {H1TemplateComponent} from "../../components/header/h1-template/h1-template.component";
import {
  SimpleCalculatorFormComponent
} from "../../components/actions-components/simple-calculator-form/simple-calculator-form.component";
import {
  SimpleCalculatorResultComponent
} from "../../components/actions-components/simple-calculator-result/simple-calculator-result.component";
import {NgClass, NgIf, NgOptimizedImage} from "@angular/common";
import {SimpleCalculatorModel} from "../../models/simple-calculator.model";
import {Meta, Title} from "@angular/platform-browser";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-votre-remuneration',
  standalone: true,
  imports: [
    H1TemplateComponent,
    SimpleCalculatorFormComponent,
    SimpleCalculatorResultComponent,
    NgIf,
    NgClass,
    NgOptimizedImage
  ],
  templateUrl: './votre-remuneration.component.html',
  styleUrl: './votre-remuneration.component.css'
})
export class VotreRemunerationComponent implements OnInit {

  resultData: SimpleCalculatorModel|null = null;
  formSave:any=null;
  animateTransition = false;
  showResult = false;

  constructor(private titleService: Title, private metaService: Meta, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.titleService.setTitle('Votre rémunération');

    this.metaService.updateTag({
      name: 'description',
      content: 'Découvrez votre rémunération en passant en portage salarial. A partir de votre TJM retrouvez vos revenu optimisé.'
    });

    this.metaService.updateTag({property: 'og:title', content: 'Votre rémunération'});
    this.metaService.updateTag({
      property: 'og:description',
      content: 'Découvrez votre rémunération en passant en portage salarial. A partir de votre TJM retrouvez vos revenu optimisé.'
    });
    this.metaService.updateTag({
      property: 'og:image',
      content: 'https://www.portagile.fr/assets/brand/logoAndTitleReverseBlue-400.webp'
    });
  }

  onFormSubmit(data: SimpleCalculatorModel) {
    this.animateTransition = true;
    this.resultData = data;
    this.showResult = true;
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  onFormSave(data:any) {
    this.formSave = data;
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  onFormRollBack() {
    this.animateTransition = true;
    this.showResult = false;
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  onAnimationEnd() {
    // Force le redimensionnement du graphique ApexCharts
    window.dispatchEvent(new Event('resize'));
  }
}
