import {Component, OnInit} from '@angular/core';
import {NgOptimizedImage} from "@angular/common";
import {H1TemplateComponent} from "../../components/header/h1-template/h1-template.component";
import {Meta, Title} from "@angular/platform-browser";
import {ActivatedRoute} from "@angular/router";

declare var $: any; // Déclaration de jQuery

@Component({
  selector: 'app-foire-aux-questions',
  standalone: true,
  imports: [
    NgOptimizedImage,
    H1TemplateComponent
  ],
  templateUrl: './foire-aux-questions.component.html',
  styleUrl: './foire-aux-questions.component.css'
})
export class FoireAuxQuestionsComponent implements OnInit {

  constructor(private titleService: Title, private metaService: Meta, private route: ActivatedRoute) {
  }

  ngOnInit(): void {

    this.titleService.setTitle('Foire aux Questions');

    this.metaService.updateTag({
      name: 'description',
      content: 'Retrouvez toutes les réponses à vos questions dans notre Foire aux Questions. '
    });

    this.metaService.updateTag({property: 'og:title', content: 'Foire aux Questions'});
    this.metaService.updateTag({
      property: 'og:description',
      content: 'Retrouvez toutes les réponses à vos questions dans notre Foire aux Questions. '
    });
    this.metaService.updateTag({
      property: 'og:image',
      content: 'https://www.portagile.fr/assets/brand/logoAndTitleReverseBlue-400.webp'
    });

    $('.FAQContentDiv1').on('click', '.question.q1', () => {
      $('.FAQContentDiv1 .question, .FAQContentDiv1 .answer').removeClass('active');
      $('.q1').addClass('active');
    });

    $('.FAQContentDiv1').on('click', '.question.q2', () => {
      $('.FAQContentDiv1 .question, .FAQContentDiv1 .answer').removeClass('active');
      $('.q2').addClass('active');
    });

    $('.FAQContentDiv1').on('click', '.question.q3', () => {
      $('.FAQContentDiv1 .question, .FAQContentDiv1 .answer').removeClass('active');
      $('.q3').addClass('active');
    });

    $('.FAQContentDiv1').on('click', '.question.q4', () => {
      $('.FAQContentDiv1 .question, .FAQContentDiv1 .answer').removeClass('active');
      $('.q4').addClass('active');
    });

    $('.FAQContentDiv1').on('click', '.question.q5', () => {
      $('.FAQContentDiv1 .question, .FAQContentDiv1 .answer').removeClass('active');
      $('.q5').addClass('active');
    });

    $('.FAQContentDiv2').on('click', '.question.q6', () => {
      $('.FAQContentDiv2 .question, .FAQContentDiv2 .answer').removeClass('active');
      $('.q6').addClass('active');
    });

    $('.FAQContentDiv2').on('click', '.question.q7', () => {
      $('.FAQContentDiv2 .question, .FAQContentDiv2 .answer').removeClass('active');
      $('.q7').addClass('active');
    });

    $('.FAQContentDiv2').on('click', '.question.q8', () => {
      $('.FAQContentDiv2 .question, .FAQContentDiv2 .answer').removeClass('active');
      $('.q8').addClass('active');
    });

    $('.FAQContentDiv2').on('click', '.question.q9', () => {
      $('.FAQContentDiv2 .question, .FAQContentDiv2 .answer').removeClass('active');
      $('.q9').addClass('active');
    });

    $('.FAQContentDiv2').on('click', '.question.q10', () => {
      $('.FAQContentDiv2 .question, .FAQContentDiv2 .answer').removeClass('active');
      $('.q10').addClass('active');
    });

    $('.FAQContentDiv3').on('click', '.question.q11', () => {
      $('.FAQContentDiv3 .question, .FAQContentDiv3 .answer').removeClass('active');
      $('.q11').addClass('active');
    });

    $('.FAQContentDiv3').on('click', '.question.q12', () => {
      $('.FAQContentDiv3 .question, .FAQContentDiv3 .answer').removeClass('active');
      $('.q12').addClass('active');
    });

    $('.FAQContentDiv3').on('click', '.question.q13', () => {
      $('.FAQContentDiv3 .question, .FAQContentDiv3 .answer').removeClass('active');
      $('.q13').addClass('active');
    });

    $('.FAQContentDiv4').on('click', '.question.q14', () => {
      $('.FAQContentDiv4 .question, .FAQContentDiv4 .answer').removeClass('active');
      $('.q14').addClass('active');
    });

    $('.FAQContentDiv4').on('click', '.question.q15', () => {
      $('.FAQContentDiv4 .question, .FAQContentDiv4 .answer').removeClass('active');
      $('.q15').addClass('active');
    });

    $('.FAQContentDiv4').on('click', '.question.q16', () => {
      $('.FAQContentDiv4 .question, .FAQContentDiv4 .answer').removeClass('active');
      $('.q16').addClass('active');
    });

    $('.FAQContentDiv4').on('click', '.question.q17', () => {
      $('.FAQContentDiv4 .question, .FAQContentDiv4 .answer').removeClass('active');
      $('.q17').addClass('active');
    });
  }
}
