export const simulatorsValues = {
  ASSURANCE_RCP_PERCENTAGE: '0.1464',
  DEFAULT_EMPLOYER_CHARGE_PERCENTAGE: '0.35',
  EMPLOYER_OVERTIME_DEDUCTION: '1.5',
  FINANCIAL_RESERVE_PERCENTAGE: '0.1',
  GENERALIZED_SOCIAL_CONTRIBUTION_TAX_RELIEF_PERCENTAGE: '0.0175',
  MANAGEMENT_FEES_PERCENTAGE: '0.05',
  MANDATORY_MUTUAL_EMPLOYER_SHARE_PERCENTAGE: '0.5',
  MANDATORY_MUTUAL_INSURANCE: '0',
  MANDATORY_MUTUAL_SALARY_SHARE_PERCENTAGE: '0.5',
  MUTUAL_EXTENSION: '0',
  OCCUPATIONAL_HEALTH_CONTRIBUTIONS: '89',
  OVERTIME_SOCIAL_EXEMPTIONS_PERCENTAGE: '0.1131',
  PAID_LEAVE_RESERVE_PERCENTAGE: '0.1',
  RESTAURANT_VOUCHERS_EMPLOYER_CONTRIBUTIONS_PERCENTAGE: '0.6',
  RESTAURANT_VOUCHERS_FACE_VALUE: '10',
  RESTAURANT_VOUCHERS_SALARY_COSTS_PERCENTAGE: '0.4',
  SALARY_COSTS_RESERVE_PERCENTAGE: '0.21',
  WEEKLY_RHYTHM: '39',
  WITHHOLDING_TAX_PERCENTAGE: '0.11'
};
