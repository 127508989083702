<div class="offerInterest">
  <!--  <h2>Notre Offre comprend</h2>-->
  <div class="cardContainer">

    <div class="swiper">
      <div class="swiper-wrapper">
        <div class="swiper-slide card card-1">
          <div class="cardImg">
            <img ngSrc="../../../../assets/media/offer-pictures/card-1-picture-500-200.webp"
                 alt="representation de proximité et qualité" height="200" width="500" priority/>
          </div>
          <div class="cardHeader">
            Proximité et qualité
          </div>
          <div class="cardContent">
            <span>Choisissez un partenaire de confiance&nbsp;!&nbsp;🤝</span>
            <p>Chez PortAgile, nous mettons l'humain au premier plan pour créer un lien solide entre nos portés et notre
              équipe.&nbsp;😊<br><br>

              La qualité de service est au cœur de notre mission. Nous nous engageons à vous offrir le meilleur service
              possible pour vous aider à atteindre vos objectifs professionnels.&nbsp;🚀</p>
          </div>
        </div>

        <div class="swiper-slide card card-2">
          <div class="cardImg">
            <img ngSrc="../../../../assets/media/offer-pictures/card-2-picture-500-200.webp"
                 alt="representation de business network" height="200" width="500" priority/>
          </div>
          <div class="cardHeader">
            Business network
          </div>
          <div class="cardContent">
            <span>N’attendez plus et trouvez une mission&nbsp;!&nbsp;🚀</span>
            <p>Rejoignez notre groupe national de plus de 200 experts IT.&nbsp;💻<br><br>

              En tant que filiale du groupe E-nov, PortAgile vous permet de bénéficier des référencements d’E-nov pour
              trouver la mission idéale.&nbsp;🔍🤝</p>
            <a href="https://e-nov.info" target="_blank" rel="noopener noreferrer" class="cardEnov">
              <img ngSrc="../../../../assets/brand/e-nov/e-nov-200.webp" alt="logo société E-NOV" height="55"
                   width="197"/>
            </a>
          </div>
        </div>

        <div class="swiper-slide card card-3">
          <div class="cardImg">
            <img ngSrc="../../../../assets/media/offer-pictures/card-3-picture-500-200.webp"
                 alt="representation de ticket restaurant" height="200" width="500" priority/>
          </div>
          <div class="cardHeader">
            Ticket restaurant
          </div>
          <div class="cardContent">
            <span>Déduisez vos frais professionnels&nbsp;!&nbsp;💼</span>
            <p>PortAgile vous propose des tickets restaurant pour optimiser vos revenus en déduisant des frais
              professionnels exonérés d’impôts.&nbsp;🍽️<br><br>

              Avec notre partenaire Swile, bénéficiez d'une carte déjeuner vous permettant de dépenser jusqu'à 25€ par
              jour travaillé dans plus de 220 établissements en France.&nbsp;💳</p>
          </div>
        </div>

        <div class="swiper-slide card card-4">
          <div class="cardImg">
            <img ngSrc="../../../../assets/media/offer-pictures/card-4-picture-500-200.webp"
                 alt="representation d'optimisation fiscale" height="200" width="500"/>
          </div>
          <div class="cardHeader">
            Optimisation fiscale
          </div>
          <div class="cardContent">
            <span>Optimisez vos revenus et votre patrimoine&nbsp;!&nbsp;💰🏡</span>
            <p>PortAgile vous offre la possibilité de bénéficier d’un accompagnement fiscal et financier grâce à notre
              partenaire en gestion de patrimoine.&nbsp;📈🤝</p>
          </div>
        </div>

        <div class="swiper-slide card card-5">
          <div class="cardImg">
            <img ngSrc="../../../../assets/media/offer-pictures/card-5-picture-500-200.webp"
                 alt="representation de réseau" height="200" width="500"/>
          </div>
          <div class="cardHeader">
            Votre réseau
          </div>
          <div class="cardContent">
            <span>Elargissez votre réseau professionnel&nbsp;!</span>
            <p>Nous savons que votre réseau est essentiel pour le développement de votre activité.<br><br>

              C'est pourquoi nous organisons des rencontres entre professionnels de votre domaine pour vous permettre de
              le développer et de le faire fructifier.&nbsp;🤝🌐</p>
          </div>
        </div>

        <div class="swiper-slide card card-6">
          <div class="cardImg">
            <img ngSrc="../../../../assets/media/offer-pictures/card-6-picture-500-200.webp"
                 alt="representation de formation" height="200" width="500"/>
          </div>
          <div class="cardHeader">
            Formation
          </div>
          <div class="cardContent">
            <span>Développez vos compétences et votre savoir-faire&nbsp;!&nbsp;📚</span>
            <p>PortAgile vous propose un catalogue de formations en relation avec votre métier, avec la possibilité
              d’une prise en charge via l’OPCO.&nbsp;🎓<br><br>

              Formez-vous continuellement et développez vos compétences pour évoluer professionnellement.&nbsp;🚀</p>
          </div>
        </div>

        <div class="swiper-slide card card-7">
          <div class="cardImg">
            <img ngSrc="../../../../assets/media/offer-pictures/card-7-picture-500-200.webp"
                 alt="representation de prime de cooptation" height="200" width="500"/>
          </div>
          <div class="cardHeader">
            Prime de cooptation
          </div>
          <div class="cardContent">
            <span>Parrainez un ami ou un collègue et gagnez 750€&nbsp;!&nbsp;💼💰</span>
            <p>PortAgile offre une prime de 750 € pour tout recrutement d'un nouveau Salarié Porté, versée après 3 mois
              de prestation.<br><br>

              Même sans être Salarié Porté, vous pouvez bénéficier de cette prime de 750 € sous forme de cadeau en guise
              de remerciement pour votre soutien.&nbsp;🎁</p>
          </div>
        </div>

        <div class="swiper-slide card card-8">
          <div class="cardImg">
            <img ngSrc="../../../../assets/media/offer-pictures/card-8-picture-500-200.webp"
                 alt="representation de remise fidélité" height="200" width="500"/>
          </div>
          <div class="cardHeader">
            Remise fidélité
          </div>
          <div class="cardContent">
            <span>Économisez de l’argent avec PortAgile&nbsp;!&nbsp;💰</span>
            <p>Dans le but de fidéliser nos salariés portés, nous avons mis en place une remise sur nos frais de gestion
              à partir d'1 an d'ancienneté.<br><br>

              Cette remise est reversée sous forme de prime annuelle, selon le barème suivant&nbsp;:</p>
          </div>
        </div>

        <div class="swiper-slide card card-9">
          <div class="cardImg">
            <img ngSrc="../../../../assets/media/offer-pictures/card-9-picture-500-200.webp"
                 alt="representation d'avance sur salaire" height="200" width="500"/>
          </div>
          <div class="cardHeader">
            Avance sur salaire
          </div>
          <div class="cardContent">
            <span>N’attendez plus de recevoir le paiement de vos clients pour être payé&nbsp;!</span>
            <p>Avec PortAgile, nous vous versons votre salaire avant même de recevoir le règlement de vos clients,
              éliminant ainsi tout stress lié aux retards de paiement éventuels de leur part.&nbsp;📅💸</p>
          </div>
        </div>

        <div class="swiper-slide card card-10">
          <div class="cardImg">
            <img ngSrc="../../../../assets/media/offer-pictures/card-10-picture-500-200.webp"
                 alt="representation de mutuelle" height="200" width="500"/>
          </div>
          <div class="cardHeader">
            Mutuelle compétitive
          </div>
          <div class="cardContent">
            <span>Bénéficiez d’une excellente couverture mutuelle à moindre prix&nbsp;!</span>
            <p>Avec PortAgile, profitez d'une mutuelle offrant des taux de remboursement dentaire et optique avantageux,
              à des tarifs négociés pour les entreprises, avec une partie prise en charge par PortAgile.&nbsp;🦷👓</p>
          </div>
        </div>

        <div class="swiper-slide card card-11">
          <div class="cardImg">
            <img ngSrc="../../../../assets/media/offer-pictures/card-11-picture-500-200.webp"
                 alt="representation de conseiller" height="200" width="500" priority/>
          </div>
          <div class="cardHeader">
            Conseiller dédié
          </div>
          <div class="cardContent">
            <span>Faites-vous accompagner par un expert du portage salarial&nbsp;!</span>
            <p>Chez PortAgile, nous sommes à votre écoute pour vous aider à développer votre activité indépendante en
              toute sérénité.&nbsp;💼💡<br><br>

              Vous pouvez compter sur notre accompagnement et nos conseils de qualité en nous joignant de 9h à 18h pour
              toute demande, question et&nbsp;...&nbsp;📞</p>
          </div>
        </div>

        <div class="swiper-slide card card-12">
          <div class="cardImg">
            <img ngSrc="../../../../assets/media/offer-pictures/card-12-picture-500-200.webp"
                 alt="representation de zéro frais cachés" height="199" width="500" priority/>
          </div>
          <div class="cardHeader">
            Zéro frais cachés
          </div>
          <div class="cardContent">
            <span>Gagnez en transparence&nbsp;!&nbsp;✨</span>
            <p>Chez PortAgile, la transparence sur vos revenus est notre ADN, comme en témoigne notre slogan : "Agilité
              et transparence pour votre portage salarial." Nous nous engageons à vous offrir une transparence totale,
              tant contractuelle que financière.&nbsp;📊<br><br>

              Chaque mois, nos salariés portés reçoivent un compte d'activité et un bulletin de salaire détaillant
              chaque charge, preuve de notre engagement à la transparence.&nbsp;📄🔍</p>
          </div>
        </div>

      </div>

      <!-- Ajoutez ceci si vous voulez des boutons de navigation -->
      <div class="swiper-button-next"></div>
      <div class="swiper-button-prev"></div>

      <!-- Ajoutez ceci si vous voulez une pagination -->
      <div class="swiper-pagination"></div>
    </div>

  </div>
</div>
