import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import {AppRoutes} from '../app-routes.enum';

import {HomeComponent} from "../../pages/home/home.component";
import {NotreOffreComponent} from "../../pages/notre-offre/notre-offre.component";
import {TrouverUneMissionComponent} from "../../pages/trouver-une-mission/trouver-une-mission.component";
import {FoireAuxQuestionsComponent} from "../../pages/foire-aux-questions/foire-aux-questions.component";
import {ContactComponent} from "../../pages/contact/contact.component";
import {PrivacyPolicyComponent} from "../../pages/privacy-policy/privacy-policy.component";
import {GestionCookiesComponent} from "../../pages/gestion-cookies/gestion-cookies.component";
import {VotreRemunerationComponent} from "../../pages/votre-remuneration/votre-remuneration.component";
import {CooptationComponent} from "../../pages/cooptation/cooptation.component";


export const routes: Routes = [
  { path: AppRoutes.HOME, component: HomeComponent },
  { path: AppRoutes.PORTAGE_SALARIAL, redirectTo: '/#portage-salarial', pathMatch: 'full' },
  { path: AppRoutes.NOTRE_OFFRE, component: NotreOffreComponent },
  { path: AppRoutes.TROUVER_UNE_MISSION, component: TrouverUneMissionComponent },
  { path: AppRoutes.FOIRE_AUX_QUESTIONS, component: FoireAuxQuestionsComponent },
  { path: AppRoutes.CONTACT, component: ContactComponent },
  { path: AppRoutes.CGU, component: PrivacyPolicyComponent },
  { path: AppRoutes.COOKIES, component: GestionCookiesComponent },
  { path: AppRoutes.REMUNERATION, component: VotreRemunerationComponent },
  { path: AppRoutes.COOPTATION, component: CooptationComponent },
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
