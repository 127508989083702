<app-h1-template [title]="'Contact'"/>
<div class="contactContainer">
  <div class="contactFormContainer">
    <div class="select" tabindex="0">
      <input class="selectopt" name="contact" type="radio" id="contact" (change)="onFormChange('contact')" [checked]="wichForm === 'contact'">
      <label for="contact" class="option">Contact</label>
      <input class="selectopt" name="contact" type="radio" id="cooptation" (change)="onFormChange('cooptation')" [checked]="wichForm === 'cooptation'">
      <label for="cooptation" class="option">Cooptation</label>
    </div>
    <app-contact-form *ngIf="this.wichForm=='contact'"></app-contact-form>
    <app-cooptation-form *ngIf="this.wichForm=='cooptation'"></app-cooptation-form>
  </div>
</div>
