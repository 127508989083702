import {DOCUMENT} from "@angular/common";
import {Inject, Injectable} from "@angular/core";

declare var grecaptcha: any;

@Injectable({
  providedIn: 'root'
})
export class RecaptchaService {
  private siteKey: string | null = null;
  private loaded: boolean = false;
  private loadPromise: Promise<void> | null = null;

  constructor(@Inject(DOCUMENT) private readonly document: Document) {
  }

  public load(siteKey: string): Promise<void> {
    if (this.loaded) {
      return Promise.resolve();
    }

    this.siteKey = siteKey;

    // Créer une promesse pour suivre le chargement du script
    this.loadPromise = new Promise<void>((resolve, reject) => {
      const script = this.document.createElement('script');
      script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
      script.async = true;
      script.defer = true;

      script.onload = () => {
        this.loaded = true;
        resolve();
      };

      script.onerror = () => {
        reject(new Error("Erreur lors du chargement de reCAPTCHA"));
      };

      this.document.head.appendChild(script);
    });

    return this.loadPromise;
  }

  public execute(action: string, callback: (token: string) => void): void {
    if (!this.siteKey) {
      throw new Error('Recaptcha site key is not set.');
    }

    if (!this.loaded) {
      throw new Error("reCAPTCHA n'est pas encore chargé.");
    }

    grecaptcha.ready(() => {
      grecaptcha.execute(this.siteKey!, {action})
        .then(callback);
    });
  }
}
