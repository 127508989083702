<h2 class="titleHowItWorks hideForMobile"><span>Le&nbsp;portage salarial </span> <span class="oval-background-pink">comment&nbsp;ca&nbsp;marche</span>&nbsp;?</h2>
<h2 class="titleHowItWorks showForMobile">comment ca marche&nbsp;?</h2>
<div class="howItWorks-global-container">
  <div class="custom-shape-divider-top-1709305510">
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
      <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" class="shape-fill"></path>
    </svg>
  </div>
  <div class="howItWorks-container">
    <div id="employeeCard" class="howItWorks-card">
      <div class="howItWorks-top-card-container">
        <div class="howItWorks-top-card-1 bg-pink">
          <svg class="howItWorks-card-svg" viewBox="0 0 800 500">
            <path
              d="M 0 100 Q 50 200 100 250 Q 250 400 350 300 C 400 250 550 150 650 300 Q 750 450 800 400 L 800 500 L 0 500"
              stroke="transparent" fill="#333"/>
          </svg>
        </div>
        <div appObserveVisibility
             targetSelectors=".howItWorks-top-card-1, .howItWorks-top-card-2, .card__line, .howItWorks-card-content"
             class="howItWorks-top-card-2">
          <svg class="howItWorks-card-svg howItWorks-card-svg-line" viewBox="0 0 800 500">
            <path class="card__line"
                  d="M 0 100 Q 50 200 100 250 Q 250 400 350 300 C 400 250 550 150 650 300 Q 750 450 800 400"
                  stroke="pink"
                  stroke-width="3" fill="transparent"/>
          </svg>
        </div>
        <div class="howItWorks-card-content">
          <h2 class="howItWorks-card-title">Salarié Porté</h2>
          <p>Le <strong>Salarié Porté</strong> a de l’expérience et des compétences reconnues ;
            Il trouve sa mission ou se fait accompagner pour la trouver;
            Puis le Salarié Porté accomplit sa mission en autonomie.</p>
        </div>
      </div>
    </div>

    <div id="portagileCard" class="howItWorks-card">
      <div class="howItWorks-top-card-container">
        <div class="howItWorks-top-card-1 bg-blue">
          <svg class="howItWorks-card-svg" viewBox="0 0 800 500">
            <path
              d="M 0 100 Q 50 200 100 250 Q 250 400 350 300 C 400 250 550 150 650 300 Q 750 450 800 400 L 800 500 L 0 500"
              stroke="transparent" fill="#333"/>
          </svg>
        </div>
        <div appObserveVisibility
             targetSelectors=".howItWorks-top-card-1, .howItWorks-top-card-2, .card__line, .howItWorks-card-content"
             class="howItWorks-top-card-2">
          <svg class="howItWorks-card-svg howItWorks-card-svg-line" viewBox="0 0 800 500">
            <path class="card__line"
                  d="M 0 100 Q 50 200 100 250 Q 250 400 350 300 C 400 250 550 150 650 300 Q 750 450 800 400"
                  stroke="pink"
                  stroke-width="3" fill="transparent"/>
          </svg>
        </div>

        <div class="howItWorks-card-content">
          <h2 class="howItWorks-card-title">PortAgile</h2>
          <h3>Vers la Société Cliente</h3>
          <p>Gestion Compta, Finance
            Formalisation et validation du Contrat commercial de prestation de service,
            Réception des commandes
            Préparation et émission de la facturation et gestion du recouvrement</p>
          <h3>Vers le Salarié Porté</h3>
          <p>
            Gestion Compta, RH

            Formalisation et validation du Contrat de travail, DUE
            Souscription Mutuelle
            Organisation Visite médicale,
            Préparation et émission des fiches de Paie,
            Gestion des frais et du compte d’activité
          </p>
        </div>
      </div>
    </div>

    <div id="companyCard" class="howItWorks-card">
      <div class="howItWorks-top-card-container">
        <div class="howItWorks-top-card-1 bg-orange">
          <svg class="howItWorks-card-svg" viewBox="0 0 800 500">
            <path
              d="M 0 100 Q 50 200 100 250 Q 250 400 350 300 C 400 250 550 150 650 300 Q 750 450 800 400 L 800 500 L 0 500"
              stroke="transparent" fill="#333"/>
          </svg>
        </div>
        <div appObserveVisibility
             targetSelectors=".howItWorks-top-card-1, .howItWorks-top-card-2, .card__line, .howItWorks-card-content"
             class="howItWorks-top-card-2">
          <svg class="howItWorks-card-svg howItWorks-card-svg-line" viewBox="0 0 800 500">
            <path class="card__line"
                  d="M 0 100 Q 50 200 100 250 Q 250 400 350 300 C 400 250 550 150 650 300 Q 750 450 800 400"
                  stroke="pink"
                  stroke-width="3" fill="transparent"/>
          </svg>
        </div>
        <div class="howItWorks-card-content">
          <h2 class="howItWorks-card-title">Société Cliente</h2>
          <p>La <strong>Société Cliente</strong> a un besoin identifié ;
            Et ne dispose pas de la compétence en interne.</p>
        </div>
      </div>
    </div>
  </div>
  <div class="custom-shape-divider-bottom-1717576748">
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
      <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" class="shape-fill"></path>
    </svg>
  </div>
</div>
