<app-h1-template [title]="'Votre rémunération'"/>
<div [ngClass]="showResult ? 'remunerationContainerResultSize' : 'remunerationContainerFormSize'"
     class="remunerationContainer">
  <img class="bgLogoPortagile" ngSrc="assets/brand/logo-300.webp" width="300" height="299" alt="PortAgile logo">
  <div class="remunerationKontext"
       [ngClass]="{ 'animate': animateTransition }"
       (animationend)="onAnimationEnd()">
    <div class="layer"
         [ngClass]="{ 'show': !showResult, 'hide': showResult, 'right': !showResult, 'left': showResult }">
      <app-simple-calculator-form (formSubmit)="onFormSubmit($event)" (formSave)="onFormSave($event)"
                                  [formRolBack]="formSave"></app-simple-calculator-form>
    </div>

    <div class="layer"
         [ngClass]="{ 'show': showResult, 'hide': !showResult, 'left': showResult, 'right': !showResult }">
      <app-simple-calculator-result (formRollBack)="onFormRollBack()"
                                    [resultData]="resultData"></app-simple-calculator-result>
    </div>
  </div>
</div>
