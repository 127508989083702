import {Component, OnInit} from '@angular/core';
import {H1TemplateComponent} from "../../components/header/h1-template/h1-template.component";
import {NgOptimizedImage} from "@angular/common";
import {Meta, Title} from "@angular/platform-browser";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-trouver-une-mission',
  standalone: true,
  imports: [
    H1TemplateComponent,
    NgOptimizedImage
  ],
  templateUrl: './trouver-une-mission.component.html',
  styleUrl: './trouver-une-mission.component.css'
})
export class TrouverUneMissionComponent implements OnInit {

  constructor(private titleService: Title, private metaService: Meta, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.titleService.setTitle('Trouver une mission');

    this.metaService.updateTag({
      name: 'description',
      content: 'Nous vous accompagnons dans la recherche de missions en mettant à votre disposition notre réseau étendu de partenaires et clients.'
    });

    this.metaService.updateTag({property: 'og:title', content: 'Trouver une mission'});
    this.metaService.updateTag({
      property: 'og:description',
      content: 'Nous vous accompagnons dans la recherche de missions en mettant à votre disposition notre réseau étendu de partenaires et clients.'
    });
    this.metaService.updateTag({
      property: 'og:image',
      content: 'https://www.portagile.fr/assets/brand/logoAndTitleReverseBlue-400.webp'
    });
  }

}
