import {Component, OnInit} from '@angular/core';
import {LandingComponent} from "../../components/content/landing/landing.component";
import {
  DetailedExplanationComponent
} from "../../components/content/detailed-explanation/detailed-explanation.component";
import {NgOptimizedImage} from "@angular/common";
import {HowItWorksComponent} from "../../components/content/how-it-works/how-it-works.component";
import {
  PortagePresentationComponent
} from "../../components/content/portage-presentation/portage-presentation.component";
import {TeamComponent} from "../../components/content/team/team.component";
import {Meta, Title} from "@angular/platform-browser";
import {ActivatedRoute} from "@angular/router";
import {TestimonialComponent} from "../../components/content/testimonial/testimonial.component";

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    LandingComponent,
    DetailedExplanationComponent,
    NgOptimizedImage,
    HowItWorksComponent,
    PortagePresentationComponent,
    TeamComponent,
    TestimonialComponent
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.css'
})
export class HomeComponent implements OnInit {

  constructor(private titleService: Title, private metaService: Meta, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.titleService.setTitle('PortAgile - Agilité et transparence pour votre portage salarial');

    this.metaService.updateTag({
      name: 'description',
      content: 'Votre partenaire de confiance pour développer votre activité professionnelle indépendante en toute sérénité. Nous nous inscrivons avec vous dans durée, la confiance et la transparence.'
    });

    this.metaService.updateTag({property: 'og:title', content: 'PortAgile - Agilité et transparence pour votre portage salarial'});
    this.metaService.updateTag({
      property: 'og:description',
      content: 'Votre partenaire de confiance pour développer votre activité professionnelle indépendante en toute sérénité. Nous nous inscrivons avec vous dans durée, la confiance et la transparence.'
    });
    this.metaService.updateTag({
      property: 'og:image',
      content: 'https://www.portagile.fr/assets/brand/logoAndTitleReverseBlue-400.webp'
    });
  }

}
