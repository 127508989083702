import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators, ReactiveFormsModule} from '@angular/forms';
import {NgClass, NgIf} from "@angular/common";
import {ContactService} from "../../../services/contact.service";
import {AppRoutes} from "../../../routing/app-routes.enum";
import {RouterLink} from "@angular/router";
import {RecaptchaService} from "../../../services/recaptcha.service";  // Reuse your contact service

@Component({
  selector: 'app-cooptation-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgIf,
    NgClass,
    RouterLink
  ],
  templateUrl: './cooptation-form.component.html',
  styleUrls: ['./cooptation-form.component.css']
})
export class CooptationFormComponent {
  cooptationForm: FormGroup;
  public readonly PORTAGILE_ROUTES_CGU: string = "/" + AppRoutes.CGU
  public readonly PORTAGILE_ROUTES_COOKIES: string = "/" + AppRoutes.COOKIES

  constructor(private readonly recaptchaService: RecaptchaService, private contactService: ContactService, private fb: FormBuilder) {
    this.cooptationForm = this.fb.group({
      coopteur_nom: ['', Validators.required],
      coopteur_prenom: ['', Validators.required],
      coopteur_mail: ['', [Validators.required, Validators.email]],
      coopteur_telephone: ['', [Validators.required, Validators.pattern(/^[0-9]{10}$/)]],

      cooptant_nom: ['', Validators.required],
      cooptant_prenom: ['', Validators.required],
      cooptant_mail: ['', [Validators.required, Validators.email]],
      cooptant_telephone: ['', [Validators.required, Validators.pattern(/^[0-9]{10}$/)]],

      commentaire: ['', Validators.required],

      consent: [false, Validators.requiredTrue],
      cookiesConsent: [false, Validators.requiredTrue],
    });
  }

  onSubmit() {
    if (this.cooptationForm.valid) {
      this.recaptchaService.load("6Le4SnAqAAAAAEXpkk0d3_P6N91Dq6akwO2_3LyN")
        .then(() => {
          this.recaptchaService.execute('contactAction', recaptchaToken => {
            // Ajout du jeton reCAPTCHA aux données du formulaire
            const model = {
              ...this.cooptationForm.value,
              recaptchaToken
            };

            this.contactService.sendCooptation(model).subscribe({
              next: response => {
                console.log("Réponse du serveur :", response);
                alert(response.message);
                this.cooptationForm.reset();
              },
              error: error => {
                if (error.status === 429) {
                  console.error("Trop de requêtes : ", error);
                  alert("Vous avez fait trop de demandes. Veuillez patienter un moment avant de réessayer.");
                } else {
                  console.error("Erreur lors de l'envoi de la requête de cooptation :", error);
                  alert("Une erreur est survenue lors de l'envoi, veuillez réessayer plus tard.");
                }
              }
            });
          });
        })
        .catch(error => {
          console.error("Erreur lors du chargement de reCAPTCHA :", error);
          alert("Impossible de charger reCAPTCHA. Veuillez réessayer plus tard.");
        });
    } else {
      console.log('Formulaire non valide');
    }
  }
}

