import {Component, OnInit} from '@angular/core';
import {H1TemplateComponent} from "../../components/header/h1-template/h1-template.component";
import {NgIf, NgOptimizedImage} from "@angular/common";
import {ContactFormComponent} from "../../components/actions-components/contact-form/contact-form.component";
import {CooptationFormComponent} from "../../components/actions-components/cooptation-form/cooptation-form.component";
import {ActivatedRoute, Router} from "@angular/router";
import {Meta, Title} from "@angular/platform-browser";

@Component({
  selector: 'app-contact',
  standalone: true,
  imports: [
    H1TemplateComponent,
    NgOptimizedImage,
    ContactFormComponent,
    CooptationFormComponent,
    NgIf
  ],
  templateUrl: './contact.component.html',
  styleUrl: './contact.component.css'
})
export class ContactComponent implements OnInit {

  wichForm: string = 'contact';

  constructor(private titleService: Title, private metaService: Meta, private route: ActivatedRoute, private router: Router) {
  }

  ngOnInit() {

    this.titleService.setTitle('Contactez-nous');

    this.metaService.updateTag({
      name: 'description',
      content: 'Contactez-nous pour toutes vos questions sur le portage salarial, nos services et nos offres.'
    });

    this.metaService.updateTag({property: 'og:title', content: 'Contactez-nous'});
    this.metaService.updateTag({
      property: 'og:description',
      content: 'Contactez-nous pour toutes vos questions sur le portage salarial, nos services et nos offres.'
    });
    this.metaService.updateTag({
      property: 'og:image',
      content: 'https://www.portagile.fr/assets/brand/logoAndTitleReverseBlue-400.webp'
    });

    this.route.queryParams.subscribe(params => {
      const formType = params['form'];

      if (formType) {
        this.wichForm = formType;
      } else {
        this.wichForm = 'contact';
      }
    });
  }

  onFormChange(formType: string) {
    this.wichForm = formType;
  }
}
