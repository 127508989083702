import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  private apiUrl = environment.apiBaseUrl;

  constructor(private http: HttpClient) {
  }

  sendContact(contactFormValues: any) {
    const requestBody = {
      recaptchaToken: contactFormValues.recaptchaToken,

      mail: contactFormValues.mail,
      telephone: contactFormValues.telephone,
      nom: contactFormValues.nom,
      prenom: contactFormValues.prenom,
      sujet: contactFormValues.sujet,
      message: contactFormValues.message,
    };

    return this.http.post<any>(this.apiUrl + '/contact', requestBody);
  }

  // New method for sending cooptation data
  sendCooptation(cooptationFormValues: any) {
    const requestBody = {
      recaptchaToken: cooptationFormValues.recaptchaToken,

      coopteur_nom: cooptationFormValues.coopteur_nom,
      coopteur_prenom: cooptationFormValues.coopteur_prenom,
      coopteur_mail: cooptationFormValues.coopteur_mail,
      coopteur_telephone: cooptationFormValues.coopteur_telephone,

      cooptant_nom: cooptationFormValues.cooptant_nom,
      cooptant_prenom: cooptationFormValues.cooptant_prenom,
      cooptant_mail: cooptationFormValues.cooptant_mail,
      cooptant_telephone: cooptationFormValues.cooptant_telephone,

      commentaire: cooptationFormValues.commentaire
    };

    return this.http.post<any>(this.apiUrl + '/cooptation', requestBody);
  }
}
