import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { HeaderBannerComponent } from './components/header/header-banner/header-banner.component';
import { FooterComponent } from './components/header/footer/footer.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, HeaderBannerComponent, FooterComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit {
  title = 'PortAgile';

  constructor(private router: Router) {}

  ngOnInit() {
    // Écouter chaque fin de navigation
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // Vérifier s'il y a un fragment dans l'URL
        const urlFragment = this.router.url.split('#')[1];
        if (urlFragment) {
          this.scrollToFragment(urlFragment);
        }
      }
    });
  }

  scrollToFragment(fragment: string) {
    setTimeout(() => {
      const element = document.getElementById(fragment);
      if (element) {
        // Décalage de 115px pour ajuster la position de l'élément
        const yOffset = -120;
        const y = element.getBoundingClientRect().top + window.scrollY + yOffset;

        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    }, 100);
  }
}
