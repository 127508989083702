import {Component, OnInit} from '@angular/core';
import {H1TemplateComponent} from "../../components/header/h1-template/h1-template.component";
import {NgOptimizedImage} from "@angular/common";
import {ActivatedRoute, RouterLink} from "@angular/router";
import {AppRoutes} from "../../routing/app-routes.enum";
import {Meta, Title} from "@angular/platform-browser";

@Component({
  selector: 'app-cooptation',
  standalone: true,
  imports: [
    H1TemplateComponent,
    NgOptimizedImage,
    RouterLink
  ],
  templateUrl: './cooptation.component.html',
  styleUrl: './cooptation.component.css'
})
export class CooptationComponent implements OnInit {

  public readonly PORTAGILE_ROUTES_CONTACT: string = '/'+AppRoutes.CONTACT

  constructor(private titleService: Title, private metaService: Meta, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.titleService.setTitle('Cooptation');

    this.metaService.updateTag({
      name: 'description',
      content: 'Des membres de votre réseau veulent se lancer en portage salarial ? Parrainez-les et gagnez 750€ !'
    });

    this.metaService.updateTag({property: 'og:title', content: 'Cooptation'});
    this.metaService.updateTag({
      property: 'og:description',
      content: 'Des membres de votre réseau veulent se lancer en portage salarial ? Parrainez-les et gagnez 750€ !'
    });
    this.metaService.updateTag({
      property: 'og:image',
      content: 'https://www.portagile.fr/assets/brand/logoAndTitleReverseBlue-400.webp'
    });
  }

}
